import { lazy } from "react";
import Loadable from "@/helpers/loadable/Loadable";
import MainTemplate from "@/components/templates/MainTemplate";
import AuthenticationGuard from "@/providers/Auth0Provider/authentication-guard";
import Auth0Callback from "@/pages/auth0-callback/Auth0CallbackPage";
import { IStorePermissions } from "@/models/storeConfiguration";

// dashboard routing
const DashboardSaintsaas = Loadable(
  lazy(() => import("@/pages/dashboard/default"))
);
const ReRoute = Loadable(lazy(() => import("@/pages/dashboard/re-route")));
const AuthCode = Loadable(lazy(() => import("@/pages/dashboard/auth-code")));
const Settings = Loadable(
  lazy(() => import("@/pages/dashboard/settings/SettingsPage"))
);
const AdCockpit = Loadable(lazy(() => import("@/pages/dashboard/ad-cockpit")));
const Support = Loadable(lazy(() => import("@/pages/dashboard/support")));
const AdStats = Loadable(lazy(() => import("@/pages/dashboard/ad-stats")));
const AdStoreCard = Loadable(
  lazy(() => import("@/pages/dashboard/ad-store-card"))
);
const AdSpend = Loadable(lazy(() => import("@/pages/dashboard/ad-spend")));
const StoreStats = Loadable(
  lazy(() => import("@/pages/dashboard/store-stats"))
);
const ChatBot = Loadable(lazy(() => import("@/pages/dashboard/chatbot")));
const AccountManagement = Loadable(
  lazy(() => import("@/pages/dashboard/account-management"))
);
const InvitePage = Loadable(lazy(() => import("@/pages/invite/InvitePage")));
const DashboardNoRoute = Loadable(
  lazy(() => import("@/pages/dashboard/no-route"))
);
const HomePage = Loadable(lazy(() => import("@/pages/home/HomePage")));

const AppRoutes = ({
  permissions,
}: {
  permissions: IStorePermissions;
  storeName: string;
}) => [
  {
    path: "/",
    element: <MainTemplate />,
    children: [
      {
        path: "/auth0-callback",
        element: <Auth0Callback />,
      },
      {
        path: "/",
        element: <DashboardNoRoute />,
      },
      {
        path: "dashboard",
        children: [
          {
            path: "",
            element: permissions?.canAccessScoreAds ? (
              <AuthenticationGuard
                component={ReRoute}
                isRouteAllowed={permissions?.canAccessScoreAds}
              />
            ) : null,
          },

          {
            path: ":id",
            element: permissions?.canAccessScoreAds ? (
              <AuthenticationGuard
                component={DashboardSaintsaas}
                isRouteAllowed={permissions?.canAccessScoreAds}
              />
            ) : null,
          },
        ],
      },
      {
        path: "settings",
        element: permissions?.canAccessSettings ? (
          <AuthenticationGuard
            component={Settings}
            isRouteAllowed={permissions?.canAccessSettings}
          />
        ) : null,
      },
      {
        path: "support",
        element: <AuthenticationGuard component={Support} />,
      },
      {
        path: "account-management",
        element: <AuthenticationGuard component={AccountManagement} />,
      },
      {
        path: "ad-stats",
        element: permissions?.canAccessAdStats ? (
          <AuthenticationGuard
            component={AdStats}
            isRouteAllowed={permissions?.canAccessAdStats}
          />
        ) : null,
      },
      {
        path: "ad-store-card",
        element: permissions?.canAccessAdStoreCard ? (
          <AuthenticationGuard
            component={AdStoreCard}
            isRouteAllowed={permissions?.canAccessAdStoreCard}
          />
        ) : null,
      },
      {
        path: "ad-spend",
        element: permissions?.canAccessScoreAdSets ? (
          <AuthenticationGuard
            component={AdSpend}
            isRouteAllowed={permissions?.canAccessScoreAdSets}
          />
        ) : null,
      },
      {
        path: "databot",
        children: [
          {
            path: "",
            element: permissions?.canAccessGenAi ? (
              <AuthenticationGuard
                component={ChatBot}
                isRouteAllowed={permissions?.canAccessGenAi}
              />
            ) : null,
          },
          {
            path: ":sessionId",
            element: permissions?.canAccessGenAi ? (
              <AuthenticationGuard
                component={ChatBot}
                isRouteAllowed={permissions?.canAccessGenAi}
              />
            ) : null,
          },
        ],
      },
      {
        path: "store-stats",
        element: permissions?.canAccessScoreAdSets ? (
          <AuthenticationGuard
            component={StoreStats}
            isRouteAllowed={true} // we don't have currently permissions for this page
          />
        ) : null,
      },
      {
        path: "adCockpit",
        children: [
          {
            path: "",
            element: permissions?.canAccessAdCockpit ? (
              <AuthenticationGuard
                component={ReRoute}
                isRouteAllowed={permissions?.canAccessAdCockpit}
              />
            ) : null,
          },

          {
            path: ":id",
            element: permissions?.canAccessAdCockpit ? (
              <AuthenticationGuard
                component={AdCockpit}
                isRouteAllowed={permissions?.canAccessAdCockpit}
              />
            ) : null,
          },
        ],
      },
    ],
  },
  {
    path: "/auth-code",
    element: <AuthenticationGuard component={AuthCode} />,
  },
  {
    path: "/invitation/:inviteId",
    exact: true,
    element: <InvitePage />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
];

export default AppRoutes;
