import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import baseApi from "@/store/baseAPI";
import filterSlice from "./slices/filters";
import storeSlice from "./slices/store";
import baseChatbotApi from "./baseChatbotAPI";

export const store = configureStore({
    reducer: {
      [baseApi.reducerPath]: baseApi.reducer,
      [baseChatbotApi.reducerPath]: baseChatbotApi.reducer,
      filters: filterSlice,
      store: storeSlice,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat([baseApi.middleware, baseChatbotApi.middleware]),
  });

type Store = typeof store;
type AppDispatch = Store["dispatch"];
type RootState = ReturnType<Store["getState"]>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
